import { useQuery } from 'apollo-client'
import { getAnalyticsContext } from 'analytics'

import type { UserGender } from 'typings/graphql'

import recommendedForYouQuery from './graph/recommendedForYou.graphql'


type UseRecommendedForYouProps = {
  gender?: UserGender
  limit?: number
  placement: string
  skip?: boolean
}

export const useRecommendedForYou = (props: UseRecommendedForYouProps) => {
  const { gender, limit, placement, skip } = props

  const { page, pageType } = getAnalyticsContext()

  const { data, isFetching } = useQuery(recommendedForYouQuery, {
    variables: {
      input: {
        gender,
        limit,
        metadata: {
          page,
          pageType,
          placement,
        },
      },
    },
    skip,
    ssr: false,
  })

  return {
    data: data?.recommendationsForYou?.data,
    isFetching,
  }
}

